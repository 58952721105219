import React from "react";
import './projects.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


function Projects() {
    return (
        <div className="projects">
            <div id="Projects" className="ProjectsHead">
                <h3>My<span> Projects & Experience</span></h3>
            </div>
            <div className="swiper-container">
                <Swiper slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                    clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                className="mySwiper"
                >
                    <SwiperSlide className="one" display = "flex">
                        <video width="100%" height="auto" controls muted> 
                            <source src={process.env.PUBLIC_URL + '/images/2023-12-08-10-26-42_1.mp4'} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="project-Text">
                        <h3><span>Motion</span> Capture Project</h3>
                        <p>This project stems from my senior design course, where my team and I collaborated with a university professor to advance research in implementing a human motion machine learning model. Our aim is to integrate this model into physical therapy practices, potentially revolutionizing rehabilitation techniques.</p>
                        <p> <span>Technologies Used: </span> Python, Unity Game Engine, C#, Barracuda learning model, Simplx body skeleton</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="two" display = "flex">
                        <img src="images/original.png" alt="project1"/>
                        <div className="project-Text">
                        <h3><span>Cryptid</span>Coid</h3>
                        <p>This project marked my debut at a hackathon. Our primary objective was to integrate Web3 technologies into a cookie-clicker-style game. Players would earn unique tokens based on their clicking achievements. Unfortunately, we did not have enough time to complete Web3 integration. This Project was my first dip into react development, as well as NOSQL databases and help me with my growth in web development.</p>
                        <p><span>Technologies Used: </span> React, FirebaseDB, HTML, Node.js, CSS</p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide className="three" display = "flex">
                        <img src="images/IMG_2490.jpg" alt="Internship"/>
                        <div className="project-Text">
                        <h3><span>Walmart Global Tech</span> Intenship</h3>
                        <p>During the summer of 2024, I had the opportunity to work at Walmart as a Software Engineer Intern. During my summer i had the opportunity to work on enterprise support tool where my goals was the redesign the application from React.js and Javascript as well as external business UI library to a internal business library, Next.js and Typescript. My main learnings from this internship was state management, UX design and how to properly display big data, cloud deployment, and Next.js interworkings in a production enviorment</p>
                        <p><span>Technologies Used: </span> Next.js, Keubernetes, Typescript, Node.js, CSS</p>
                    </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="project-box">
                <div className="projects-container">
                    <div className="video-container">
                        <video width="100%" height="auto" controls muted>
                            <source src={process.env.PUBLIC_URL + '/images/2023-12-08-10-26-42_1.mp4'} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="project-Text">
                        <h3><span>Motion</span> Capture Project</h3>
                        <p>This project stems from my senior design course, where my team and I collaborated with a university professor to advance research in implementing a human motion machine learning model. Our aim is to integrate this model into physical therapy practices, potentially revolutionizing rehabilitation techniques.</p>
                        <p>Technologies Used: Python, Unity Game Engine, C#, Barracuda learning model, Simplx body skelaton</p>
                    </div>
                </div>
            </div>
            <div className="project-box">
                <div className="projects-container">
                    <div className="image-container">
                        <img src="images/original.png" alt="project1" />
                    </div>
                    <div className="project-Text">
                        <h3><span>Cryptid</span>Coid</h3>
                        <p>This project marked my debut at a hackathon. Our primary objective was to integrate Web3 technologies into a cookie-clicker-style game. Players would earn unique tokens based on their clicking achievements. Unfortunately, we did not have enough time to complete Web3 integration. This Project was my first dip into react development, as well as NOSQL databases and help me with my growth in web development.</p>
                        <p>Technologies Used: React, FirebaseDB, HTML, Node.js, CSS</p>
                    </div>
                </div>
            </div>
            <div className="project-box">
                <div className="projects-container">
                    <div className="image-container">
                        <img src="images/IMG_2490.jpg" alt="internship" />
                    </div>
                    <div className="project-Text">
                    <h3><span>Walmart Global Tech</span> Intenship</h3>
                        <p>During the summer of 2024, I had the opportunity to work at Walmart as a Software Engineer Intern. During my summer i had the opportunity to work on enterprise support tool where my goals was the redesign the application from React.js and Javascript as well as external business UI library to a internal business library, Next.js and Typescript. My main learnings from this internship was state management, UX design and how to properly display big data, cloud deployment, and Next.js interworkings in a production enviorment</p>
                        <p><span>Technologies Used: </span> Next.js, Keubernetes, Typescript, Node.js, CSS</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Projects;
